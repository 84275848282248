<template>
  <div class="company flex flex-col bg-yellow min-h-screen relative pt-8">
    <p
      class="text-white absolute top-5 left-5 underline cursor-pointer"
      @click="$router.go(-1)"
    >
      ATRÁS
    </p>
    <div
      class="flex flex-col items-center justify-start self-center w-11/12"
      v-if="company"
    >
      <h2 class="font-bold text-2xl">{{ company.name }}</h2>

      <div
        class="grid grid-cols-3 gap-4 my-4 border-b border-black w-full py-8"
      >
        <div class="flex flex-col items-start">
          <p class="mb-0">
            <span class="font-bold">Ciudad:</span> {{ company.city }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Dirección:</span> {{ company.address }}
          </p>
          <p class="mb-0">
            <span class="font-bold">NIT:</span> {{ company.nit }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Tracksales:</span> {{ company.cid }}
          </p>
        </div>

        <div class="flex flex-col items-start">
          <p class="mb-0">
            <span class="font-bold">Grupo:</span> {{ company.group }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Trabajadores:</span> {{ users }}
          </p>
          <p class="mb-0" v-if="ownersLength === 0">
            <span class="font-bold">Dueño:</span> {{ ownersLength }}
          </p>
          <p class="mb-0" v-else>
            <span class="font-bold">Dueño:</span>
            <router-link
              :to="`/user/${company.owner}`"
              target="_blank"
              class="underline !text-black"
              >{{ ownersLength }}</router-link
            >
          </p>
          <p class="mb-0">
            <span class="font-bold">Dependientes:</span> {{ workersLength }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Máximos trabajadores:</span>
            {{ company.maxWorkers }}
          </p>
        </div>

        <div class="flex flex-col items-start">
          <p class="mb-0">
            <span class="font-bold">Vendedor:</span> {{ company.salesman }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Teléfono:</span> {{ company.salesmanPhone }}
          </p>
        </div>
      </div>

      <form
        @submit.prevent="updateMaxWorkers"
        class="w-full flex flex-col items-center gap-5 py-8 border-b border-black"
      >
        <h2 class="font-bold text-2xl">Actualizar cupos</h2>
        <div class="flex items-center gap-5">
          <label for="maxWorkersInput" class="text-lg"
            >Ingrese el nuevo valor:</label
          >
          <input
            id="maxWorkersInput"
            v-model="maxWorkers"
            required
            type="number"
            class="px-5 py-2 bg-white rounded-md text-center"
          />
        </div>
        <small
          >Recuerde el nuevo valor no puede ser menor al número de trabajadores
          registrados</small
        >
        <button
          class="px-7 py-2 rounded-md cursor-pointer font-bold text-lg text-white bg-red-500"
        >
          Actualizar
        </button>
        <span v-if="errorMessage" class="text-red-600">{{ errorMessage }}</span>
      </form>

      <div class="grid grid-cols-3 gap-4 py-8 border-b border-black">
        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Total Meta</p>
          <p class="mb-0">${{ Intl.NumberFormat().format(goal.total) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">
            Total Ventas actuales
          </p>
          <p class="mb-0">${{ Intl.NumberFormat().format(currentGoal) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Porcentaje cumplido</p>
          <p class="mb-0">{{ getPercentageGoal(currentGoal, goal.total) }}%</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Meta Dry</p>
          <p class="mb-0">${{ Intl.NumberFormat().format(goal.dry) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Ventas actuales Dry</p>
          <p class="mb-0">${{ Intl.NumberFormat().format(currentGoalDry) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Porcentaje cumplido</p>
          <p class="mb-0">{{ getPercentageGoal(currentGoalDry, goal.dry) }}%</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Meta Snack</p>
          <p class="mb-0">${{ Intl.NumberFormat().format(goal.snack) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">
            Ventas actuales Snack
          </p>
          <p class="mb-0">
            ${{ Intl.NumberFormat().format(currentGoalSnack) }}
          </p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Porcentaje cumplido</p>
          <p class="mb-0">
            {{ getPercentageGoal(currentGoalSnack, goal.snack) }}%
          </p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Meta Wet</p>
          <p class="mb-0">${{ Intl.NumberFormat().format(goal.wet) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Ventas actuales Wet</p>
          <p class="mb-0">${{ Intl.NumberFormat().format(currentGoalWet) }}</p>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-xl text-center mb-0 font-bold">Porcentaje cumplido</p>
          <p class="mb-0">{{ getPercentageGoal(currentGoalWet, goal.wet) }}%</p>
        </div>
      </div>

      <div class="flex flex-col items-start w-full">
        <h2 class="text-xl font-bold mb-2">Compras</h2>
        <v-data-table
          :headers="headers"
          :items="purchases"
          :items-per-page="10"
          class="w-full"
          :loading="loadingData"
          loading-text="Cargando información"
        >
          <template v-slot:[`item.timestamp`]="{ item }">
            {{ getTimestamp(item.timestamp) }}
          </template>
        </v-data-table>
      </div>

      <!-- <div class="flex flex-col items-start w-full mt-4">
        <h2 class="text-xl font-bold mb-2">Agregar Compra</h2>
        <form @submit.prevent="onSubmit" class="mb-4">
          <input type="number" class="py-2 px-4 rounded-lg mr-4 bg-white" v-model="amount" placeholder="Cantidad (solo números)">
          <button class="bg-white text-yellow py-2 px-4 rounded-lg">Agregar</button>
        </form>
      </div> -->

      <!-- <div class="flex flex-col items-start w-full my-4">
        <h2 class="text-xl font-bold mb-2">Metas por periodo</h2>
        <v-data-table
          :headers="headersGoals"
          :items="compliance"
          :items-per-page="10"
          class="w-full"
          :loading="loadingData"
          loading-text="Cargando información"
        >
          <template v-slot:[`item.totalAmount`]="{ item }">
            {{ formatMoney(item.totalAmount) }}
          </template>

          <template v-slot:[`item.percentage`]="{ item }">
            {{ `${item.percentage}%` }}
          </template>
        </v-data-table>
      </div> -->
    </div>

    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import Loader from "../components/Loader.vue";
export default {
  components: { Loader },

  data() {
    return {
      isLoading: true,
      loadingData: true,
      company: null,
      users: 0,
      actualPurchases: [],
      purchases: [],
      headers: [
        {
          text: "Cantidad",
          align: "start",
          value: "amount",
        },
        {
          text: "Cantidad Dry",
          align: "start",
          value: "amountDry",
        },
        {
          text: "Cantidad Snack",
          align: "start",
          value: "amountSnack",
        },
        {
          text: "Cantidad Wet",
          align: "start",
          value: "amountWet",
        },
        { text: "Periodo", value: "period" },
        { text: "Creado", value: "timestamp" },
      ],
      goal: {
        total: 0,
        dry: 0,
        wet: 0,
        snack: 0,
      },
      currentGoal: 0,
      currentGoalDry: 0,
      currentGoalSnack: 0,
      currentGoalWet: 0,
      amount: 0,
      headersGoals: [
        {
          text: "Periodo",
          align: "start",
          value: "period",
        },
        { text: "Ventas", value: "totalAmount" },
        { text: "Porcentaje", value: "percentage" },
      ],
      workersLength: 0,
      maxWorkers: 0,
      ownersLength: 0,
      compliance: [],
      errorMessage: "",
    };
  },

  async mounted() {
    const res = await this["companies/getCompanyById"](this.$route.params.uid);
    if (res.success) {
      this.company = res.data;
      this.maxWorkers = res.data.maxWorkers;
    }

    const res2 = await this["companies/getCompanyPurchases"]();
    if (res2.success) {
      this.purchases = res2.purchases;
      this.actualPurchases = res2.actualPurchases;

      if (res.goals) {
        this.goal = res.goals[res2.currentPeriod];
      }

      res2.actualPurchases.forEach((purchase) => {
        this.currentGoal = this.currentGoal + purchase.amount;
        this.currentGoalDry = this.currentGoal + purchase.amountDry;
        this.currentGoalSnack = this.currentGoal + purchase.amountSnack;
        this.currentGoalWet = this.currentGoal + purchase.amountWet;
      });

      this.loadingData = false;
    }

    const res3 = await this["companies/getCompanyUsers"](
      this.$route.params.uid
    );
    if (res3.success) {
      this.users = res3.users;
      this.workersLength = res3.workersLength;
      this.ownersLength = res3.ownersLength;
    }

    const res4 = await this["companies/getCompanyCompliance"](
      this.$route.params.uid
    );
    if (res4.success) {
      this.compliance = res4.compliance;
    }

    this.isLoading = false;
  },

  methods: {
    ...mapActions([
      "companies/getCompanyById",
      "companies/getCompanyPurchases",
      "companies/setPurchase",
      "companies/getCompanyUsers",
      "companies/getCompanyCompliance",
      "companies/updateMaxWorkers",
    ]),

    getTimestamp(time) {
      return moment(time).format("YYYY/MM/DD HH:mm");
    },

    async updateMaxWorkers() {
      this.errorMessage = "";
      if (this.maxWorkers == this.company.maxWorkers) return;

      this.isLoading = true;
      const res = await this["companies/updateMaxWorkers"]({
        companyData: this.company,
        newValue: this.maxWorkers,
      });

      if (res.success) {
        this.company.maxWorkers = this.maxWorkers;
      } else {
        this.errorMessage = res.message;
      }
      this.isLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      const res = await this["companies/setPurchase"]({
        amount: parseFloat(this.amount),
        companyId: this.company.cid,
      });

      // this.loadingData = true;
      // const res2 = await this["companies/getCompanyPurchases"]();
      // if(res2.success) {
      //   this.purchases = res2.purchases;
      //   this.actualPurchases = res2.actualPurchases;
      //   this.currentGoal = this.currentGoal + parseFloat(this.amount);
      //   this.loadingData = false;
      // }
      if (res.success) {
        this.purchases = [
          ...this.purchases,
          {
            amount: parseFloat(this.amount),
            amountDry: 0,
            amountSnack: 0,
            amountWet: 0,
            companyId: this.company.cid,
            period: "p13",
            timestamp: Date.now(),
            year: "2022",
          },
        ];
      }
      this.amount = 0;
      this.isLoading = false;
    },

    getPercentageGoal(currentGoal, goal) {
      const percentage = (currentGoal / goal) * 100;
      if (isNaN(percentage)) {
        return 0;
      } else {
        return percentage.toFixed(2);
      }
    },

    formatMoney(number) {
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        maximumFractionDigits: 0,
      });
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  font-family: "Nunito";
}
</style>
